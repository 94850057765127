export const generateOrderID = () =>
  (
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toUpperCase()

export const comparePrices = (arr1, arr2) => {
  const filtered1 = arr1
    .map((x) => ({ id: x.id, price: x.price }))
    .sort((a, b) => (a.id > b.id) - (a.id < b.id))
    //.sort((a, b) => (a.price > b.price ? -1 : 0))
    .reduce((acc, c) => {
      acc[c.id] = c.price
      return acc
    }, {})
  const filtered2 = arr2
    .map((x) => ({ id: x.id, price: x.price }))
    .sort((a, b) => (a.id > b.id) - (a.id < b.id))
    //.sort((a, b) => (a.price > b.price ? -1 : 0))
    .reduce((acc, c) => {
      acc[c.id] = c.price
      return acc
    }, {})

  return JSON.stringify(filtered1) === JSON.stringify(filtered2)
}

export const groupProducts = (arr) =>
  arr.reduce(
    (acc, c) => {
      if (c.isMiljo) {
        acc.miljo.push(c)
        return acc
      }
      if (c.compatible) {
        acc.compatible.push(c)
        return acc
      }
      if (!c.compatible) {
        acc.original.push(c)
        return acc
      }
      return acc
    },
    { original: [], compatible: [], miljo: [] }
  )

export const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')
