import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  Flex,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  Heading,
  Textarea,
  FormControl,
  FormLabel,
  Center,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export const TipsModal = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [submitted, setSubmitted] = useState(false)

  return (
    <>
      <Flex justifyContent='center' direction='column'>
        <Text size='sm'>Fann tú ikki tí vøru ið tú leitaði eftir?</Text>
        <Text>Boða okkum frá og vit gera hvat vit kunnu fyri at hjálpa!</Text>
        <Button
          onClick={onOpen}
          color='black'
          fontWeight='bold'
          size='sm'
          my={3}
        >
          Fráboðanarskjal
        </Button>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
        scrollBehavior='inside'
        blockScrollOnMount={false}
      >
        <form
          onSubmit={handleSubmit((d) => {
            fetch('https://farva.fo/api/suggestions', {
              method: 'POST',
              body: JSON.stringify(d),
            })
              .then((res) => res.json())
              .then((d) => {
                if (d) setSubmitted(true)
              })
              .catch((err) => setSubmitted(true))
          })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!submitted && 'Send okkum tína fráboðan her'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {submitted ? (
                <Center>
                  <Heading color='green.300'>Tak fyri !</Heading>
                </Center>
              ) : (
                <VStack spacing={12}>
                  <Text>
                    Vit stremba altíð eftir at gera tað betur, tí seta vit prís
                    á tína fráboðan!
                  </Text>
                  <FormControl id='printerOrProduct'>
                    <FormLabel>Tín teldupostur</FormLabel>
                    <Input
                      type='text'
                      placeholder='olav@dømi.fo'
                      {...register('email', { required: true })}
                    />
                  </FormControl>
                  <FormControl id='printerOrProduct'>
                    <FormLabel>Navn á printara ella vøru</FormLabel>
                    <Input
                      type='text'
                      placeholder='Laserjet Pro xx, svart patron, model: xxx....'
                      {...register('product', { required: true })}
                    />
                  </FormControl>
                  <FormControl id='comment'>
                    <FormLabel>Nærri lýsing</FormLabel>
                    <Textarea
                      {...register('comment', { required: false })}
                      placeholder='Vinarliga lýs tín tørv nærri her, soleiðis at vit betri kunnu hjálpa tær í framtíðini.'
                    />
                  </FormControl>
                </VStack>
              )}
            </ModalBody>
            <ModalFooter>
              {!submitted && (
                <Button type='submit' colorScheme='blue' size='lg' mr={6}>
                  Send
                </Button>
              )}
              <Button
                variant='link'
                colorScheme='black'
                mr={3}
                onClick={onClose}
              >
                Lukka
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}
