import { gql } from '@apollo/client'

export const GET_PRODUCTS_FROM_PRINTER = gql`
  query products($printerID: uuid!) {
    printers_by_pk(id: $printerID) {
      id
      products(where: { product: { isActive: { _eq: true } } }) {
        product {
          price
          title
          id
          webStock
          compatible
          capacity
          isMulti
          isMiljo
          img
          colors
          model
          hasDiscount
          pricePreDiscount
          discountPercentage
          slug
        }
      }
    }
  }
`

export const GET_SERIES = gql`
  query series($brandID: uuid!) {
    printer_brands_by_pk(id: $brandID) {
      id
      series {
        id
        name
      }
    }
  }
`
export const GET_PRINTERS = gql`
  query printer($seriesID: uuid!) {
    printer_series_by_pk(id: $seriesID) {
      id
      printers {
        id
        name
      }
    }
  }
`
export const GET_INITAL_DATA = gql`
  query Products {
    products(
      limit: 50
      order_by: { webStock: desc, hasDiscount: desc }
      where: { isActive: { _eq: true } }
    ) {
      price
      title
      id
      webStock
      compatible
      capacity
      isMulti
      isMiljo
      img
      colors
      model
      hasDiscount
      pricePreDiscount
      discountPercentage
      slug
    }
    printer_brands(order_by: { sorting: asc }) {
      id
      name
    }
  }
`

export const NEW_ORDER = gql`
  mutation newOrder($order: orderDetails!) {
    newOrder(order: $order) {
      url
    }
  }
`
export const GET_PRODUCTS_BY_TERM = gql`
  query GET_PRODUCTS_BY_TERM($term: String!) {
    products(
      limit: 100
      order_by: { webStock: desc }
      where: {
        _or: [{ title: { _ilike: $term } }, { model: { _ilike: $term } }]
        _and: [{ isActive: { _eq: true } }]
      }
    ) {
      price
      title
      id
      webStock
      compatible
      capacity
      isMulti
      isMiljo
      img
      colors
      model
      hasDiscount
      pricePreDiscount
      discountPercentage
      slug
    }
  }
`
// excluded the printer query
// { printers: { printer: { name: { _ilike: $term } } } }

export const GET_PRINTERS_BY_PRODUCT_SLUG = gql`
  query GET_PRODUCT($slug: String!) {
    products(where: { slug: { _eq: $slug } }) {
      price
      title
      id
      webStock
      compatible
      capacity
      isMulti
      isMiljo
      img
      colors
      model
      hasDiscount
      pricePreDiscount
      discountPercentage
      slug
      brand
      type
      printers {
        printer {
          name
          slug
        }
      }
    }
  }
`
export const GET_PRINTERS_BY_PRODUCT_ID = gql`
  query GET_PRINTERS_BY_PRODUCT_ID($id: uuid!) {
    products_by_pk(id: $id) {
      printers {
        printer {
          id
          name
          slug
        }
      }
    }
  }
`
export const GET_PRODUCTS_BY_PRINTER_SLUG = gql`
  query GET_PRODUCTS_BY_PRINTER_SLUG($slug: String!) {
    printers(where: { slug: { _eq: $slug } }) {
      name
      products {
        product {
          price
          title
          id
          webStock
          compatible
          capacity
          isMulti
          isMiljo
          img
          colors
          model
          hasDiscount
          pricePreDiscount
          discountPercentage
          slug
        }
      }
    }
  }
`
