import { Checkbox, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { WizardVerticalSteps } from '.'

const filterOptions = [
  /*{
    id: 'color',
    title: 'Color',
    options: [
      { id: 'black', title: 'Black' },
      { id: 'cyan', title: 'Cyan' },
      { id: 'magenta', title: 'Magenta' },
      { id: 'yellow', title: 'Yellow' },
      { id: 'multi', title: 'Multi / Pack' },
    ],
  },
  {
    id: 'compatible',
    title: 'Slag',
    options: [
      { id: 'original', title: 'Original' },
      { id: 'compatible', title: 'Kompatibul' },
    ],
  }*/
]

export const FilterSidebar = ({ children, brands }) => (
  <Flex direction='column' grow='1'>
    <VStack spacing='8' align='start'>
      <WizardVerticalSteps brands={brands} />
      {filterOptions.map((x) => (
        <FilterGroup key={x.id} title={x.title} options={x.options} id={x.id} />
      ))}
    </VStack>
  </Flex>
)

const FilterGroup = ({ title, options }) => (
  <Flex direction='column' w='100%'>
    <Heading
      as='h5'
      size='xs'
      color='gray.400'
      textTransform='uppercase'
      letterSpacing='0.1rem'
      fontWeight='semibold'
      mb='1'
    >
      {title}
    </Heading>
    <Flex direction='column'>
      {options.map((o) => (
        <FilterItem key={o.id} title={o.title} />
      ))}
    </Flex>
  </Flex>
)

const FilterItem = ({ title }) => (
  <HStack pl='4' mt='2'>
    <Checkbox size='lg' border='#30C4E1' colorScheme='twitter' />
    <Text fontSize='sm'>{title}</Text>
  </HStack>
)
