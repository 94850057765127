import React, { useContext } from 'react'
import Link from 'next/link'
import { CartContext } from '../store'
import { PrinterListPopover } from '.'
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  VStack,
  Button,
  Divider,
  Text,
  Tag,
  useToast,
  Badge,
} from '@chakra-ui/react'

const colorMap = {
  bk: 'black',
  c: 'cyan',
  m: 'magenta',
  y: 'yellow',
  gy: 'grey',
  cmy: 'linear-gradient(45deg,cyan,magenta,yellow)',
  mbk: '#28282B',
  pbk: 'black',
  lc: '#E0FFFF',
  lm: '#FF77FF',
  ph: 'linear-gradient(45deg,cyan,magenta,black)',
}

export const ProductTile = ({
  price,
  title,
  id,
  webStock,
  colors = [],
  img = null,
  model,
  pricePreDiscount,
  hasDiscount = false,
  discountPercentage,
  slug,
}) => (
  <Flex
    direction='column'
    bg='white'
    borderRadius='lg'
    w={{ md: '15rem', sm: '90%' }}
    minH='13rem'
    m='5'
    p='4'
    border='1px solid'
    borderColor='gray.100'
    boxShadow='0px 5px 5px 0px #9999991f'
  >
    <Flex justifyContent='space-between'>
      <Flex>
        {colors.codes.map((x, i) => (
          <Tag key={x + i} opacity='0.8' bg={colorMap[x]} mr={2}></Tag>
        ))}
      </Flex>
      <PrinterListPopover id={id} />
    </Flex>
    <Center h='100%'>
      <VStack justifyContent='space-between' h='100%'>
        <Box p={3}>
          <Image
            height='125'
            width='125'
            alt='blekkpatron'
            src={img || 'https://placehold.it/125x125'}
          />
        </Box>
        <Flex textAlign='left' w='100%' justifyContent='space-between'>
          <Box>
            {hasDiscount && (
              <Text
                color='gray.500'
                fontSize='1rem'
                textDecoration='line-through'
              >
                {pricePreDiscount} kr.
              </Text>
            )}
            <Text color='green.300' fontSize='1.6rem'>
              {price} kr.
            </Text>
          </Box>
          {hasDiscount && (
            <Box mr={2}>
              <Badge
                bg='yellow'
                color='black'
                p={3}
                transform='rotate(-20deg)'
                fontSize='1rem'
                boxShadow='0px 4px 10px 0px #0000004a'
              >
                {discountPercentage} %
              </Badge>
            </Box>
          )}
        </Flex>
        <Divider />

        <Heading
          as='h4'
          fontSize='sm'
          fontWeight='300'
          _hover={{
            color: 'blue.600',
          }}
        >
          <Link href={`/product/${slug}`}>
            <a>
              {title} {model}
            </a>
          </Link>
        </Heading>

        <Divider />
        <Box h='2rem' w='100%'>
          <Text
            color={webStock > 0 ? 'green.400' : 'orange.400'}
            noOfLines='1'
            fontSize='.8rem'
          >
            {webStock > 0
              ? 'á goymslu - veitan 1-2 dagar'
              : 'ikki á goymslu - veitan 2-3 vikur'}
          </Text>
        </Box>

        <BuyButton id={id} title={title} price={price} />
      </VStack>
    </Center>
  </Flex>
)

export const BuyButton = ({ id, title, price, size = null, w = null }) => {
  const [cart, setCart] = useContext(CartContext)
  const toast = useToast()
  const itemExists = cart.items.find((x) => x.id === id) || null

  const toastOptions = {
    title: 'Lagt til kurv',
    description: title.substr(0, 25) + '...' || '',
    status: 'success',
    position: 'top-right',
    duration: 1200,
    isClosable: true,
  }

  function addItemToCart() {
    toast(toastOptions)
    if (itemExists) {
      const updatedItemsArray = cart.items.map((x) =>
        x.id === id ? { ...x, quantity: x.quantity + 1 } : x
      )
      return setCart({ ...cart, items: updatedItemsArray })
    }
    return setCart({
      ...cart,
      items: [...cart.items, { id, title, price, quantity: 1 }],
    })
  }

  return (
    <Button
      colorScheme={!itemExists ? 'green' : 'teal'}
      size={size || 'md'}
      minW={w ? '0px' : '90%'}
      w={w || 'auto'}
      mt='0.5rem'
      fontSize='0.8rem'
      letterSpacing='0.1rem'
      fontWeight='900'
      onClick={addItemToCart}
    >
      KEYP{' '}
      {itemExists && (
        <Badge bg='#00000021' pos='absolute' top='0.3rem' right='0.3rem'>
          {itemExists.quantity}
        </Badge>
      )}
    </Button>
  )
}
