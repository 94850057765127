import { VStack, Text, Heading, Button } from '@chakra-ui/react'

export const CompatibleText = () => (
  <VStack textAlign='left' alignItems='start' spacing={6} p={2}>
    <Text>
      Tær kompatiblu printarapatrónirnar eru av somu dygd ella betri, sum tær
      orginalu patrónirnar.
    </Text>

    <Text>
      Summar av patróninum kunnu vera “remanufactured” ið meinast, at tað eru
      orginalar patrónur, ið nýtt blekk er komið í. Allar kompatiblar
      blekkpatrónir, tónarar og trumlur frá okkara veitara, eru framleiddar og
      testaðar ífylgi ISO-9001 góðkendum fabrikkum ið tryggjar, at kundin fær
      eina álítandi vøru til ein bíligan pening.
    </Text>
    <Heading size='md'>
      Hvat er munurin á orginalum og kompatiblum patrónum?
    </Heading>
    <Text>
      Prísurin er tann størsti munurin, tú kanst í miðal spara 80%, um tú keypur
      kompatiblar patrónur.
    </Text>
    {/*<Heading size='md'>
      Dekkar garantiði á printaranum, um eg brúki kompatiblarpatrónur?
    </Heading>
    <Text>
      Um tú brúkar kompatiblar blekkpatrónur og tónarar til tín printara, er
      garanti enn galdandi sambært danskari lóg. Tað er ólógligt fyri ein
      framleiðara, at avmarka teg til bert at brúka blekkpatrónur ella tónarar
      frá framleiðaranum sjálvum.
</Text>*/}

    <Text>
      Vit miða eftir at kunna veita vøruna skjótt og bílligt til tín, vit royna
      tí altíð at hava eitt breitt úrval. Fanst tú ikki tað ið tú leitaði eftir?
      Skriva so til okkara á{' '}
      <a style={{ color: 'blue' }} href='mailto:farva@farva.fo'>
        farva@farva.fo
      </a>
    </Text>
  </VStack>
)
