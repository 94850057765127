import { Flex, Box } from '@chakra-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import { CartButton, Search } from './'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { UiContext } from '../store'
export const Headerbar = ({ withSearch = false }) => (
  <Flex
    direction={['column', 'row']}
    bg='#00000070'
    justify='space-between'
    p='6'
    borderBottom='1px solid #d3d3d34d'
  >
    <Logo />
    {withSearch && <Search />}

    <CartButton />
  </Flex>
)

const Logo = () => {
  const router = useRouter()
  const [ui, setUi] = useContext(UiContext)

  const handleClick = (e) => {
    e.preventDefault()
    setUi({ ...ui, isSearching: false, searchTerm: null })
    return router.push('/')
  }

  return (
    <Box textAlign='center' mb={[6, 0]} cursor='pointer'>
      <a href='/' onClick={handleClick}>
        <Image
          priority={true}
          src='/logo.svg'
          width={150}
          height={40}
          alt='logo'
        />
      </a>
    </Box>
  )
}
