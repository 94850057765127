import { Box, Stack, Button, Text, HStack } from '@chakra-ui/react'
import { useContext, useEffect, useRef } from 'react'
import { Step } from './Step'
import { StepContent } from './StepContent'
import { Steps } from './Steps'
import { useSteps } from './useSteps'
import Select from 'react-select'
import { FilterContext } from '../../store'
import { SeriesSelect, PrinterSelect } from '../'

export const WizardVerticalSteps = ({ brands }) => {
  const { nextStep, prevStep, reset, activeStep, setActiveStep } = useSteps({
    initialStep: 0,
  })
  const [filter, setFilter] = useContext(FilterContext)
  const BrandSelectRef = useRef()
  const SeriesSelectRef = useRef()
  const PrinterSelectRef = useRef()

  const { brand, series } = filter

  useEffect(() => {
    if (SeriesSelectRef.current && brand) SeriesSelectRef.current.clearValue()
    if (BrandSelectRef.current && !brand) {
      BrandSelectRef.current.clearValue()
      reset()
    }
  }, [brand])

  useEffect(() => {
    if (PrinterSelectRef.current && series)
      PrinterSelectRef.current.clearValue()
  }, [series])

  return (
    <Box mx='auto' maxW='2xl' py={[0, 10]} minH={[0, '400px']} w='100%'>
      <Steps activeStep={activeStep} setActiveStep={setActiveStep}>
        <Step title='Vel slag'>
          <StepContent>
            <Stack shouldWrapChildren spacing={[2, 4]}>
              <Text>Vel slag</Text>
              <Select
                ref={BrandSelectRef}
                instanceId='brandselect'
                id='brandselect'
                name='brand'
                onChange={(d, n) => {
                  if (n.action !== 'clear') {
                    setFilter({ ...filter, [n.name]: d.value })
                    nextStep()
                  }
                }}
                options={brands.map((x) => ({ value: x, label: x.name }))}
                placeholder='vel'
              />
            </Stack>
          </StepContent>
        </Step>
        <Step title='Vel seriu'>
          <StepContent>
            <Stack shouldWrapChildren spacing='4'>
              <Text>Vel seriu</Text>
              <SeriesSelect
                name='series'
                ref={SeriesSelectRef}
                onChange={(d, n) => {
                  if (n.action !== 'clear') {
                    setFilter({ ...filter, [n.name]: d.value })
                    nextStep()
                  }
                }}
                placeholder='vel'
                autoFocus
              />
              )
            </Stack>
          </StepContent>
        </Step>
        <Step title='Vel printara'>
          <StepContent>
            <Stack shouldWrapChildren spacing='4'>
              <Text>Vel printara</Text>
              <PrinterSelect
                name='printer'
                ref={PrinterSelectRef}
                onChange={(d, n) => {
                  if (n.action !== 'clear') {
                    setFilter({ ...filter, [n.name]: d.value })
                    nextStep()
                  }
                }}
                placeholder='vel'
              />
            </Stack>
          </StepContent>
        </Step>
      </Steps>
      <HStack
        display={activeStep === 3 ? 'flex' : 'none'}
        mt='10'
        spacing='4'
        shouldWrapChildren
      >
        <Button
          size='sm'
          onClick={(_) => {
            reset()
            setFilter({ ...filter, brand: null, series: null, printer: null })
          }}
          variant='outline'
          verticalAlign='baseline'
        >
          Strika val
        </Button>
      </HStack>
    </Box>
  )
}
