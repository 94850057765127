import { Flex, Spinner, Text, Center, Heading, Divider } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { ProductTile, TipsModal } from '../c'
import { ProductContext } from '../store'
import { useQuery } from '@apollo/client'
import { FilterContext, UiContext } from '../store'
import { GET_PRODUCTS_FROM_PRINTER } from '../gql'
import { groupProducts } from '../utils/helpers'

export const ProductGallery = ({ initProducts }) => {
  const [products, setProducts] = useContext(ProductContext)
  const [ui, setUi] = useContext(UiContext)
  const [filter] = useContext(FilterContext)
  const { printer } = filter

  const { loading, error, data, refetch } = useQuery(
    GET_PRODUCTS_FROM_PRINTER,
    {
      variables: { printerID: printer && printer.id },
      skip: Boolean(!printer),
    }
  )

  useEffect(() => {
    printer &&
      refetch({
        printerID: printer && printer.id,
      })
  }, [printer])

  useEffect(() => {
    if (printer) {
      const newProducts =
        data?.printers_by_pk.products.map((x) => x.product) || []
      setProducts([...newProducts])
    }
  }, [data])

  const productSorter = (a, b) => {
    if (a.hasDiscount) return -1
    if (Boolean(a.webStock)) {
      if (!a.isMulti) return 0
      return 0
    }
    if (!a.isMulti) return 1
    return 1
  }

  const groupedProducts =
    printer || ui.isSearching
      ? groupProducts(products)
      : groupProducts(initProducts)

  if (loading)
    return (
      <Center w='100%' h='100%'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Center>
    )

  return (
    <Flex w='100%' wrap='wrap'>
      <Flex w='100%' minH='2rem'>
        {printer && (
          <Heading
            as='h2'
            size={['sm', 'md']}
            fontWeight='600'
            pt={4}
            pb={3}
            px={[2, 0]}
          >
            Vit funnu {products.length}{' '}
            {products.length === 1 ? 'vøru' : 'vørur'} til tín{' '}
            <span style={{ color: 'teal' }}>{printer.name}</span>
          </Heading>
        )}
        {!printer && ui.isSearching && (
          <Heading
            as='h2'
            size={['sm', 'md']}
            fontWeight='600'
            mt={4}
            mb={3}
            px={[2, 0]}
          >
            Vit funnu {products.length === 100 ? '100+' : products.length}{' '}
            {products.length === 1 ? 'vøru' : 'vørur'} ið tú leitar eftir,{' '}
            {products.length === 100 &&
              'vinarliga skriva eina neyvari lýsing av tí tú leitar eftir.'}
          </Heading>
        )}
      </Flex>

      {groupedProducts.compatible && (
        <ProductGroup
          title='Kompatibul'
          products={groupedProducts.compatible.sort(productSorter)}
        />
      )}
      {groupedProducts.original && (
        <ProductGroup
          title='Orginal'
          products={groupedProducts.original.sort(productSorter)}
        />
      )}
      {groupedProducts.miljo && (
        <ProductGroup
          title='Miljø'
          products={groupedProducts.miljo.sort(productSorter)}
        />
      )}
      {!products.length && <TipsModal />}
    </Flex>
  )
}

const ProductGroup = ({ products = [], title }) =>
  products.length ? (
    <>
      <Heading size='md' mb={2} mt={2} w='95%' p={3} bg='whitesmoke'>
        {title}
      </Heading>
      {products.map((product) => (
        <ProductTile key={product.id} {...product} />
      ))}
    </>
  ) : (
    ''
  )
