import {
  IconButton,
  Text,
  Table,
  Tr,
  Td,
  TableCaption,
  Thead,
  Divider,
  Th,
  Tbody,
  Flex,
} from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'

import { useContext } from 'react'
import { CartContext } from '../store'
import { QuantityButton } from './'
import { roundToTwo } from '../utils/helpers'

const shippingCost = 67.5
export const CheckoutOrderSummary = () => {
  const [cart, setCart] = useContext(CartContext)
  return (
    <Flex
      p={[0, '2rem']}
      direction='column'
      borderRadius='.5rem'
      boxShadow='0px 3px 7px 0px #0000002e'
      bg='white'
    >
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Vøra</Th>
            <Th textAlign='center'>Prísur</Th>
            <Th textAlign='center' maxW='1rem'>
              Nøgd
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {cart.items.map((x) => (
            <CartItem key={x.id} {...x} />
          ))}
        </Tbody>
        <TableCaption>
          <Text textAlign='right'>
            Samla: {roundToTwo(cart.total(cart) * 0.8)},-
          </Text>
          <Text textAlign='right'>
            MVG: {roundToTwo(cart.total(cart) * 0.2)},-
          </Text>
          <Text textAlign='right'>
            Fragt: {cart.shippingType === 'deliver' ? shippingCost : '0'},-
          </Text>

          <Divider />
          <Text mt={2} fontSize='lg' textAlign='right'>
            <span style={{ fontSize: '0.8rem', marginRight: '.5rem' }}>
              Samla upphædd:{' '}
            </span>
            {cart.shippingType === 'deliver' ? (
              <span style={{ color: 'green' }}>
                DKK {cart.total(cart) + shippingCost}
              </span>
            ) : (
              <span style={{ color: 'green' }}>DKK {cart.total(cart)}</span>
            )}
          </Text>
        </TableCaption>
      </Table>
    </Flex>
  )
}

const CartItem = ({ id, title, price, quantity }) => {
  const [cart, setCart] = useContext(CartContext)
  function removeItemFromCart() {
    setCart({ ...cart, items: cart.items.filter((x) => x.id !== id) })
  }
  return (
    <Tr>
      <Td p='3' maxW='170px'>
        <Text fontSize='xs'>{title}</Text>
      </Td>
      <Td p='3'>
        <Text align='center' fontSize='xs'>
          {price}
        </Text>
      </Td>
      <Td p='3'>
        <QuantityButton ea={quantity} id={id} />
      </Td>
      <Td p='0'>
        <IconButton
          size='sm'
          variant='ghost'
          icon={<SmallCloseIcon />}
          onClick={removeItemFromCart}
        ></IconButton>
      </Td>
    </Tr>
  )
}
