import {
  VStack,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'

export const TreytirText = () => (
  <VStack textAlign='left' alignItems='start' spacing={6} p={2}>
    <Heading size='md'>Prísir</Heading>

    <Text>
      Allir prísirnir er í føroyskum krónum (DKK). Fyrivarni verður tikið fyri
      útseldari vøru og prísbroytingum.
    </Text>
    <Heading size='md'>Gjald</Heading>

    <Text>
      Bíløgd vøra skal gjaldast umvegis gjaldsskipanina á heimasíðu okkara ella
      umvegis bankaflyting.
      <br />
      Gjaldast kann við hesum gjaldskortum: Visakort og Mastercard.
    </Text>
    <Text>Bankaflyting á kontu: 9181-5577928</Text>
    <Text>
      Keypsupphæddin verður ikki trekt av gjaldskorti tínum, fyrr enn vøran
      verður avheintað av goymlsuni ella, tá hon verður send við Posta ella
      aðrari fraktfyritøku. Gjaldingin fer fram gjøgnum Quickpay. Hetta er
      viðurkend og trygg gjaldsskipan. Vit goyma ikki gjaldsupplýsingarnar.
      Allir prísir eru við mvg, um annað ikki er tilskilað. Fyrivarni verður
      tikið fyri útseldari vøru og prísbroytingum.
    </Text>
    <Heading size='md'>Trygd og Cookies</Heading>
    <Text>
      Fyri at kunna keypa frá okkum, er neyðugt at upplýsa persónligar
      upplýsingar, so sum navn, bústað, teldupostadressu, telefonnummar.
      Farva.fo verjir persónligu upplýsingarnar, sum tú flýggjar.
    </Text>
    <Text>
      Upplýsingarnar verða bara brúktar til at avgreiða títt keyp. Tað er ein
      tøknilig fyritreyt, um keypast skal um telduna, at ávísar upplýsingar
      verða goymdar á tíni teldu. Hetta verður nevnt ”cookies”. Tað vil siga, at
      síðan kennir teg aftur, tá ið tú vitjar síðuna aftur.
    </Text>
    <Text>
      Tínar upplýsingar verða ikki givnar víðari ella seldar til ein triðja
      persón, tó verður tín teldupost adressa og títt fartelefonnummar givið
      víðari til Posta, tá ið Posta ber pakkan út.
    </Text>
    <Text>
      Vit nýta trygdarprokollina SSL, sum fjalir upplýsingarnar millum
      internetkagan (kortánara) og internetservera (handilin).
    </Text>

    <Heading size='md'>Sending og veiting</Heading>

    <Text>
      Váttan verður send við móttøku av tínum ordra. Allir ordrar móttiknir
      undan kl. 9, vera sendir sama daga treytað av, at vøran er á goymslu. Um
      vøran er á goymslu, er veitingin 1-2 dagar og fraktgjaldið er 59 kr.
    </Text>
    <Text>
      Vøran kann eisini heintast á fylgjandi adressu:
      <br />
      Kovalág 3
      <br />
      FO- 100 Tórshavn
    </Text>

    <Heading size='md'>Iðranarrættur og umbýting</Heading>

    <Text>
      Allar vørur kunnu latast inn aftur innan 14 dagar frá móttøkudegnum,
      treytað av, at vøran er óbrúkt, lýtaleys og í upprunaligum pakningi. Hetta
      er tó ikki galdandi fyri vørur, ið vit serstakt hava útvegað fyri kundan.
      Allar vørur eru fevndar av 2 ára trygd, tó ikki upp á slitdeilir ella
      skeivari nýtslu av vøruni. Vøran kann latast aftur til farva.fo treytað
      av, at hon er í upprunainnpakningi.
    </Text>

    <Heading size='md'>Um okkum</Heading>

    <Text>
      Vit hava stovnað okkara nethandil við tí endamáli at veita føroyingum eina
      skjóta og góða tænastu við dygdargóðum vørum til ein bílligan penga.
    </Text>
    <Text>
      Hetta gera vit við at hava lágar útreiðslur og onga sølubúð. Tað, vit
      spara í rakstri, skal koma tær til góðar.
    </Text>
    <Text>
      Vit miða ímóti at hava okkara vørur á goymslu. Finnur tú ikki tað, tú
      leitar eftir, skriva til okkara á{' '}
      <a style={{ color: 'blue' }} href='mailto:farva@farva.fo'>
        farva@farva.fo
      </a>
    </Text>
    <Text>Vit gleða okkum at tæna tær á okkara nethandli 😊</Text>
  </VStack>
)

export const TreytirModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        onClick={onOpen}
        variant='link'
        color='black'
        fontWeight='bold'
        size='sm'
      >
        handilstreytirnar
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
        scrollBehavior='inside'
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Keypstreytir</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TreytirText />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Lukka
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
