import { Button } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'

import { useRouter } from 'next/router'
import { useContext } from 'react'
import { CartContext } from '../store'

export const CheckoutButton = () => {
  const router = useRouter()
  const [cart] = useContext(CartContext)
  if (cart.items.length) {
    return (
      <Button
        colorScheme='teal'
        borderRadius={0}
        size='lg'
        zIndex='999'
        pos='fixed'
        w='100%'
        bottom='0'
        right='0'
        rightIcon={<ArrowForwardIcon />}
        onClick={(e) => {
          e.preventDefault()
          return router.push('/checkout')
        }}
      >
        Til keypskurv
      </Button>
    )
  }
  return <></>
}
