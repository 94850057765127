import { useContext, forwardRef } from 'react'
import { useQuery } from '@apollo/client'
import Select from 'react-select'
import { FilterContext } from '../store'
import { GET_PRINTERS } from '../gql'

export const PrinterSelect = forwardRef((props, ref) => {
  const [filter] = useContext(FilterContext)
  const { series } = filter
  const { loading, error, data } = useQuery(GET_PRINTERS, {
    variables: { seriesID: series && series.id },
    skip: Boolean(!series),
  })
  const printers = data?.printer_series_by_pk.printers || []

  return (
    <Select
      instanceId='printari'
      id='printari'
      ref={ref}
      {...props}
      options={
        data &&
        printers.map((printer) => ({ value: printer, label: printer.name }))
      }
    />
  )
})
//value, label
