import { useQuery } from '@apollo/client'
import {
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent,
  Spinner,
  Tag,
  Flex,
} from '@chakra-ui/react'
import { BsPrinter } from 'react-icons/bs'
import Link from 'next/link'

import { GET_PRINTERS_BY_PRODUCT_ID } from '../gql'

export const PrinterListPopover = ({ id }) => {
  return (
    <Popover isLazy lazyBehavior='unmount'>
      <PopoverTrigger>
        <IconButton variant='outline' size='xs' icon={<BsPrinter />} />
      </PopoverTrigger>
      <PopoverContent size='sm'>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <PopoverData id={id} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const PopoverData = ({ id }) => {
  const { data, loading, error } = useQuery(GET_PRINTERS_BY_PRODUCT_ID, {
    variables: { id },
  })

  if (loading) return <Spinner />
  if (error) return 'error'

  return (
    <Flex wrap='wrap'>
      {data.products_by_pk.printers.map((x) => (
        <Link href={'/printer/' + x.printer.slug}>
          <Tag
            size='sm'
            fontSize='0.7rem'
            variant='outline'
            m={1}
            key={x.printer.id}
            cursor='pointer'
          >
            {x.printer.name.split(' ').slice(1).join(' ')}
          </Tag>
        </Link>
      ))}
    </Flex>
  )
}
