import { useContext, forwardRef } from 'react'
import { gql, useQuery } from '@apollo/client'
import Select from 'react-select'
import { FilterContext } from '../store'
import { GET_SERIES } from '../gql'

export const SeriesSelect = forwardRef((props, ref) => {
  const [filter] = useContext(FilterContext)
  const { brand } = filter
  const { loading, error, data } = useQuery(GET_SERIES, {
    variables: { brandID: brand && brand.id },
    skip: Boolean(!brand),
  })
  const series = data?.printer_brands_by_pk.series || []

  return (
    <Select
      instanceId='seriu'
      id='seriu'
      ref={ref}
      {...props}
      options={
        data && series.map((serie) => ({ value: serie, label: serie.name }))
      }
    />
  )
})
//value, label
