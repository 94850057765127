import {
  IconButton,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
  Input,
  Box,
} from '@chakra-ui/react'
import {
  ArrowForwardIcon,
  SmallCloseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons'

import { AiOutlineShopping } from 'react-icons/ai'
import { useState, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { CartContext } from '../store'

export const CartButton = () => {
  const [cart, setCart] = useContext(CartContext)
  const router = useRouter()
  return (
    <Popover isLazy={true}>
      <PopoverTrigger>
        <Box
          position={['fixed', 'relative']}
          top={[3, 0]}
          right={[3, 0]}
          zIndex='999'
        >
          {Boolean(cart.items.length) && (
            <Flex
              pos='absolute'
              justify='center'
              align='center'
              top='-0.3rem'
              left='-0.3rem'
              bg='white'
              zIndex='9'
              borderRadius='10rem'
              w='22px'
              h='22px'
              p='0.2rem'
              color='black'
              fontSize='.9rem'
              fontWeight='500'
              border='2px solid #1da1f2'
            >
              {cart.items.length}
            </Flex>
          )}
          <IconButton
            aria-label='shopping cart'
            icon={<Icon w='7' h='7' as={AiOutlineShopping} />}
            colorScheme='twitter'
            size='lg'
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Keypskurv</PopoverHeader>
        <PopoverBody
          maxH='700px'
          overflowY='scroll'
          boxSizing='border-box'
          boxShadow='inset 0px 0px 20px 0px #0000000f'
          bg='white'
        >
          {cart.items.length ? (
            <Table>
              <Tbody>
                {cart.items.map((x) => (
                  <CartItem key={x.id} {...x} />
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text align='center' fontStyle='italic' color='grey' p={4}>
              Tin keypskurv er tom
            </Text>
          )}
        </PopoverBody>
        <PopoverFooter>
          <Flex direction='row' justify='space-between' align='baseline'>
            <Text>
              <span style={{ fontSize: '0.7rem' }}>Samla:</span>{' '}
              {cart.total(cart)} DKK
            </Text>
            <Button
              disabled={!cart.items.length}
              colorScheme='teal'
              rightIcon={<ArrowForwardIcon />}
              onClick={(e) => {
                e.preventDefault()
                router.push('/checkout')
              }}
            >
              Til keypskurv
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

const CartItem = ({ id, title, price, quantity }) => {
  const [cart, setCart] = useContext(CartContext)
  function removeItemFromCart() {
    setCart({ ...cart, items: cart.items.filter((x) => x.id !== id) })
  }
  return (
    <Tr>
      <Td p='3'>
        <Text fontSize='xs'>{title}</Text>
      </Td>
      <Td p='3'>
        <Text fontSize='xs'>{price}</Text>
      </Td>
      <Td p='3'>
        <QuantityButton ea={quantity} id={id} />
      </Td>
      <Td p='0'>
        <IconButton
          size='sm'
          variant='ghost'
          icon={<SmallCloseIcon />}
          onClick={removeItemFromCart}
        ></IconButton>
      </Td>
    </Tr>
  )
}

export const QuantityButton = ({ ea = 0, id }) => {
  const [quantity, setQuantity] = useState(ea)
  const [cart, setCart] = useContext(CartContext)

  useEffect(() => {
    const cartItemsUpdatedCount = cart.items.map((x) =>
      x.id === id ? { ...x, quantity: quantity } : x
    )

    return setCart({ ...cart, items: cartItemsUpdatedCount })
  }, [quantity])

  function decrease() {
    if (quantity > 1) setQuantity(quantity - 1)
  }
  function increase() {
    if (quantity < 99) setQuantity(quantity + 1)
  }
  function inputQuantity({ target }) {
    let value = +target.value
    if (value >= 1 && value < 100) setQuantity(value)
  }

  return (
    <Flex maxW='6rem'>
      <IconButton
        aria-label='Decrease quantity'
        variant='outline'
        icon={<ChevronDownIcon />}
        size='sm'
        borderRightRadius='0'
        onClick={decrease}
      />
      <Input
        textAlign='center'
        minW='2rem'
        mr='-px'
        ml='-px'
        value={cart.items.find((x) => x.id === id).quantity}
        size='sm'
        p='0'
        onChange={inputQuantity}
      />

      <IconButton
        aria-label='Increase quantity'
        variant='outline'
        icon={<ChevronUpIcon />}
        size='sm'
        borderLeftRadius='0'
        onClick={increase}
      />
    </Flex>
  )
}
