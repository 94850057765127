import { useMutation } from '@apollo/client'
import {
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Box,
  useColorModeValue as mode,
  SimpleGrid,
  VStack,
  Text,
  Divider,
  Flex,
  Spinner,
  Center,
  Heading,
} from '@chakra-ui/react'

import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CartContext } from '../store'
import { NEW_ORDER } from '../gql'
import { TreytirModal } from '.'

export const CheckoutForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const [cart, setCart] = useContext(CartContext)
  const [sendOrder, { loading, data, error }] = useMutation(NEW_ORDER)

  useEffect(() => {
    if (errors) console.log(errors)
    return
  }, [errors])

  if (data?.newOrder?.url && !loading && !error) {
    window.location.href = data.newOrder.url
    return (
      <Center h='100%' w='100%'>
        <VStack>
          <Heading>Til gjaldingar</Heading>
          <Spinner size='lg' />
        </VStack>
      </Center>
    )
  }

  return (
    <Box w='100%'>
      <form
        id='settings-form'
        onSubmit={handleSubmit((d) => {
          sendOrder({
            variables: {
              order: {
                ...d,
                ...cart,
                shippingType: cart.shippingType,
                total: cart.total(cart),
              },
            },
          })
        })}
      >
        <VStack align='left' spacing={6}>
          <SimpleGrid columns={2} spacing={6}>
            <FormControl id='firstname'>
              <FormLabel>Fornavn</FormLabel>
              <Input
                type='text'
                placeholder='Fornavn'
                {...register('firstname', { required: true })}
                isInvalid={errors.firstname}
                errorBorderColor='red.400'
              />
              {errors.firstname && (
                <FormHelperText color='red'>
                  <span color='red.400'>Teigurin skal útfyllast</span>
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id='lastname'>
              <FormLabel>Eftirnavn</FormLabel>
              <Input
                type='text'
                placeholder='Eftirnavn'
                {...register('lastname', { required: true })}
                isInvalid={errors.lastname}
                errorBorderColor='red.400'
              />
              {errors.lastname && (
                <FormHelperText color='red'>
                  <span color='red.400'>Teigurin skal útfyllast</span>
                </FormHelperText>
              )}
            </FormControl>
          </SimpleGrid>
          <FormControl id='email'>
            <FormLabel>T-postur</FormLabel>
            <Input
              type='email'
              placeholder='mitt.navn@mail.fo'
              {...register('email', { required: true })}
              isInvalid={errors.email}
              errorBorderColor='red.400'
            />
            {errors.email && (
              <FormHelperText color='red'>
                <span color='red.400'>Teigurin skal útfyllast</span>
              </FormHelperText>
            )}
          </FormControl>
          <FormControl id='tlf'>
            <FormLabel>Telefonnummar</FormLabel>
            <Input
              type='tel'
              placeholder='000000'
              {...register('tlf', { required: true })}
              isInvalid={errors.tlf}
              errorBorderColor='red.400'
            />
            {errors.tlf && (
              <FormHelperText color='red'>
                <span color='red.400'>Teigurin skal útfyllast</span>
              </FormHelperText>
            )}
          </FormControl>

          <FormControl id='company'>
            <FormLabel>Virki</FormLabel>
            <Input
              placeholder='virki navn'
              type='text'
              {...register('company')}
            />
          </FormControl>

          <Divider mt={2} />

          <FormControl id='address'>
            <FormLabel>Bústaður</FormLabel>
            <Input
              type='text'
              placeholder='Adressa 1'
              {...register('address1', { required: true })}
              isInvalid={errors.address1}
              errorBorderColor='red.400'
            />
            {errors.address && (
              <FormHelperText color='red'>
                <span color='red.400'>Teigurin skal útfyllast</span>
              </FormHelperText>
            )}
            <Input
              placeholder='Adressa 2'
              type='text'
              {...register('address2')}
              mt={2}
            />
          </FormControl>

          <Flex spacing={6}>
            <FormControl id='zip' w='50%' mr={6}>
              <FormLabel>Postnummar</FormLabel>
              <Input
                type='number'
                placeholder='100'
                {...register('zip', { required: true, min: 100, max: 999 })}
                isInvalid={errors.zip}
                errorBorderColor='red.400'
              />
              {errors.zip && (
                <FormHelperText color='red'>
                  <span color='red.400'>Ikki gyldig nummar</span>
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id='city'>
              <FormLabel>Býur</FormLabel>
              <Input
                type='text'
                placeholder='Torshavn'
                {...register('city', { required: true })}
                isInvalid={errors.city}
                errorBorderColor='red.400'
              />
              {errors.city && (
                <FormHelperText color='red'>
                  <span color='red.400'>Teigurin skal útfyllast</span>
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
          <FormControl id='shipping_type'>
            <FormLabel>Shipping</FormLabel>
            <RadioGroup
              value={cart.shippingType}
              onChange={(v) => setCart({ ...cart, shippingType: v })}
              name='shipping_type'
            >
              <SimpleGrid
                columns={[1, 2]}
                spacing={[4, 1]}
                align='center'
                mt={3}
                mb={3}
              >
                <Radio size='lg' value='pickup'>
                  Heinta (Torshavn)
                </Radio>
                <Radio size='lg' value='deliver'>
                  Senda (Posta 67.50,-)
                </Radio>
              </SimpleGrid>
            </RadioGroup>
          </FormControl>
          <Divider />
          <FormControl id='terms'>
            <Checkbox
              size='lg'
              name='terms'
              {...register('terms', { required: true })}
            >
              <Text fontSize='sm' maxW='90%'>
                Eg vátti at givnu upplýsingarnar eru rættar og góðtaki{' '}
                <TreytirModal />
              </Text>
            </Checkbox>
            {errors.terms && (
              <FormHelperText color='red'>
                <span color='red.400'>treytirnar ma góðtakast</span>
              </FormHelperText>
            )}
          </FormControl>
        </VStack>
        <Button
          isLoading={loading}
          loadingText='Til gjaldingar'
          type='submit'
          colorScheme='green'
          size='lg'
          mt='3rem'
          w='100%'
          disabled={!cart.items.length}
        >
          Víðari til gjalding
        </Button>
        {data && <Text>{data?.newOrder?.url && data.newOrder.url}</Text>}
        {!cart.items.length && <Text color='red'>Einki produkt i kurv.</Text>}
        {error && <Text color='red'>{error.message}</Text>}
      </form>
    </Box>
  )
}
