import { Flex, Center } from '@chakra-ui/react'
import { Footer } from '.'

export const BaseLayout = ({ children }) => (
  <Flex
    direction='column'
    minW='100vw'
    bg='repeating-linear-gradient(45deg, #137468, #1cab91 600px)'
  >
    <Center>
      <Flex
        direction='column'
        w='100%'
        minH='100vh'
        maxW='1900px'
        boxShadow={['none', '0 0 20px 1px #0000005c']}
      >
        {children}
        <Footer />
      </Flex>
    </Center>
  </Flex>
)
