import Head from 'next/head'
export const HtmlHead = ({
  title = 'Farva',
  description = 'Printa meir fyri minni!',
  img = 'https://res.cloudinary.com/farva/image/upload/v1635385165/blekk/wjxovwu4zimly1hogiw1.jpg',
  url = 'https://farva.fo/',
}) => (
  <Head>
    <title>{title}</title>
    <meta name='description' content={description} />
    <meta
      name='viewport'
      content='width=device-width,height=device-height,initial-scale=1.0,user-scalable=no,user-scalable=0'
    />
    <meta name='title' content='Farva' />

    <meta property='og:type' content='website' />
    <meta property='og:url' content={url} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:image' content={img} />

    <meta property='twitter:card' content='summary_large_image' />
    <meta property='twitter:url' content={url} />
    <meta property='twitter:title' content={title} />
    <meta property='twitter:description' content={description} />
    <meta property='twitter:image' content={img} />
  </Head>
)
