import { Flex, Center, Heading, Text } from '@chakra-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import { Link as ChakraLink } from '@chakra-ui/react'

export const Footer = () => (
  <Center pt={12} pb={24} bg='whitesmoke'>
    <Flex direction='column'>
      <Flex direction='row'>
        <Flex direction='column' mr={12}>
          <Heading size='sm'> Farva.fo</Heading>
          <Text>
            <Link href='/'>
              <ChakraLink>Heim</ChakraLink>
            </Link>
          </Text>
          <Text>
            <Link href='/okkum'>
              <ChakraLink>Um okkum</ChakraLink>
            </Link>
          </Text>
          <Text>
            <Link href='/treytir'>
              <ChakraLink>Treytir</ChakraLink>
            </Link>
          </Text>
        </Flex>
        <Flex direction='column'>
          <Heading size='sm'> Samband</Heading>
          <Text>
            <a href='mailto:farva@farva.fo'>farva@farva.fo</a>
          </Text>
          {/* <Text>75 00 75</Text> */}
          <Text>Kovalág 3</Text>
          <Text>FO-100 Tórshavn </Text>
        </Flex>
      </Flex>
      <Center mt={6}>
        <Text fontSize='.6rem' color='gray.400'>
          <b>Døgg sp/f - v-tal: 646075</b>
        </Text>
      </Center>

      <Center mt={6}>
        <Image src='/visacard.png' width={30} height={20} alt='visa' />
        <Image
          src='/mastercard.png'
          width={30}
          height={20}
          alt='mastercard'
        />{' '}
      </Center>
    </Flex>
  </Center>
)
