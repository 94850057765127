import { useContext, useEffect, useState } from 'react'
import { InputGroup, InputLeftElement, Input, Spinner } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { FilterContext, ProductContext, UiContext } from '../store'
import { GET_PRODUCTS_BY_TERM } from '../gql'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'

export const Search = () => {
  const [term, setTerm] = useState('')
  const [filters, setFilters] = useContext(FilterContext)
  const [products, setProducts] = useContext(ProductContext)
  const [ui, setUi] = useContext(UiContext)
  const [search, { data, error, loading }] = useLazyQuery(GET_PRODUCTS_BY_TERM)
  const debouncedSearch = debounce(search, 500)

  useEffect(() => {
    if (filters.printer) setTerm('')
  }, [filters.printer])
  useEffect(() => {
    if (data?.products) setProducts([...data.products])
  }, [data])
  useEffect(() => {
    setUi({ ...ui, isSearching: Boolean(term.length > 1), searchTerm: term })
  }, [term])
  useEffect(() => {
    !ui.isSearching && !ui.searchTerm && setTerm('')
  }, [ui])

  function updateTerm(e) {
    const searchTerm = e.target.value
    setTerm(searchTerm)
    if (filters.brand) {
      setFilters({
        brand: null,
        series: null,
        printer: null,
        color: null,
        compatible: null,
      })
    }

    if (searchTerm.length > 1)
      debouncedSearch({ variables: { term: `%${searchTerm}%` } })
    return
  }

  return (
    <InputGroup w={['100%', '60%']} mb={[3, 0]}>
      <InputLeftElement
        pointerEvents='none'
        children={
          loading ? <Spinner size='sm' /> : <SearchIcon color='gray.300' />
        }
      />
      <Input
        value={term}
        type='text'
        onChange={updateTerm}
        placeholder='Leita eftir vøru..'
        bg='white'
      />
    </InputGroup>
  )
}
