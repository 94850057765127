import { Flex } from '@chakra-ui/react'

export const Sidebar = ({ children }) => (
  <Flex
    p='6'
    bg='white'
    borderRight='1px solid #d3d3d3c2'
    minW='19%'
    direction='column'
  >
    {children}
  </Flex>
)
