import { useContext } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Circle,
  Collapse,
  Heading,
  HStack,
  Icon,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { useStep } from './useStep'
import { FilterContext } from '../../store'

const stepToFilterMapping = ['brand', 'series', 'printer']

export const Step = (props) => {
  const [filter, setFilter] = useContext(FilterContext)
  const { title, children, ...boxProps } = props
  const { isActive, isCompleted, step, setActiveStep } = useStep()
  const accentColor = useColorModeValue('blue.500', 'blue.300')
  const mutedColor = useColorModeValue('gray.600', 'whiteAlpha.800')
  const activeColor = useColorModeValue('white', 'black')
  const currentFilter = filter[stepToFilterMapping[step - 1]]
  const previousFilterCompleted =
    step === 1 || filter[stepToFilterMapping[step - 2]]
  return (
    <Box {...boxProps}>
      <HStack spacing={[2, 4]}>
        <Circle
          size='8'
          fontWeight='bold'
          color={
            isActive ? activeColor : isCompleted ? accentColor : mutedColor
          }
          bg={isActive ? accentColor : 'transparent'}
          borderColor={isCompleted ? accentColor : 'inherit'}
          borderWidth={isActive ? '0px' : '1px'}
        >
          {isCompleted ? <Icon as={CheckIcon} /> : step}
        </Circle>
        <Box
          onClick={(_) => {
            previousFilterCompleted && setActiveStep(step - 1)
            if (step === 1)
              setFilter({ ...filter, series: null, printer: null })
            if (step === 2) setFilter({ ...filter, printer: null })
          }}
        >
          <Heading
            fontSize='lg'
            fontWeight='semibold'
            color={isActive || isCompleted ? 'inherit' : mutedColor}
            cursor='pointer'
          >
            {title}
          </Heading>

          {!isActive && currentFilter && (
            <Text color='teal'>{currentFilter && currentFilter.name}</Text>
          )}
        </Box>
      </HStack>
      <Collapse in={isActive}>{children}</Collapse>
    </Box>
  )
}
